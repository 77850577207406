import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { Table, Button, Typography } from 'antd';
import axios from 'axios';

const { Title } = Typography;

export default function InvoicesOverview() {
  const [invoices, setInvoices] = useState([]);

  useEffect(() => {
    const fetchInvoices = async () => {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_API_BASE_URL}/invoices`,
        );
        setInvoices(response.data);
      } catch (error) {
        console.error('Error fetching invoices', error);
      }
    };

    fetchInvoices();
  }, []);

  const columns = [
    {
      title: 'Invoice ID',
      dataIndex: '_id',
      key: '_id',
    },
    {
      title: 'Contact Name',
      dataIndex: ['contact', 'name'],
      key: 'contact.name',
    },
    {
      title: 'Total Amount',
      dataIndex: 'totalAmount',
      key: 'totalAmount',
      render: (text) => `${text.toFixed(2)} CHF`,
    },
    {
      title: 'Invoice Date',
      dataIndex: 'invoiceDate',
      key: 'invoiceDate',
      render: (text) => new Date(text).toLocaleDateString(),
    },
    {
      title: 'Due Date',
      dataIndex: 'dueDate',
      key: 'dueDate',
      render: (text) => new Date(text).toLocaleDateString(),
    },
    {
      title: 'Actions',
      key: 'actions',
      render: (text, record) => (
        <Link to={`/invoices/edit/${record._id}`}>
          <Button type="primary">Edit</Button>
        </Link>
      ),
    },
  ];

  return (
    <div>
      <Title level={2}>Invoices Overview</Title>
      <Table
        columns={columns}
        dataSource={invoices}
        rowKey="_id"
        pagination={{ pageSize: 10 }}
      />
    </div>
  );
}
