import React, {
  useState,
  useEffect,
  createContext,
  useContext,
  useRef,
} from 'react';
import {
  BrowserRouter as Router,
  Route, Routes, useNavigate,
} from 'react-router-dom';
import { Layout, Menu, Drawer } from 'antd';
import {
  BookOutlined, ContactsOutlined, WalletOutlined,
} from '@ant-design/icons';
import Keycloak from 'keycloak-js';
import axios from 'axios'; // Import axios
import CreateInvoice from './Invoices/CreateInvoice';
import EditInvoice from './Invoices/EditInvoice';
import Contacts from './Contacts/Contacts';
import CreateContacts from './Contacts/CreateContacts';
import InvoicesOverview from './Invoices/InvoicesOverview';
import TransactionList from './Transactions/TransactionList';
import EditTransaction from './Transactions/EditTransaction';
import CreateTransaction from './Transactions/CreateTransaction';
import Overview from './Accounting/Overview';

const { Header, Sider, Content } = Layout;

const menuItems = [
  {
    key: 'contacts',
    label: 'Contacts',
    icon: <ContactsOutlined />,
    children: [
      { key: 'contacts', label: 'Manage Contacts' },
      { key: 'contacts/create', label: 'Create Contacts' },
    ],
  },
  {
    key: 'invoices',
    label: 'Invoices',
    icon: <WalletOutlined />,
    children: [
      { key: 'invoices', label: 'Invoices Overview' },
      { key: 'invoices/create', label: 'Create Invoice' },
    ],
  },
  {
    key: 'accounting',
    label: 'Accounting',
    icon: <BookOutlined />,
    children: [
      { key: 'accounting', label: 'Balance & Income Statement' },
      { key: 'transactions', label: 'Manage Transactions' },
      { key: 'transactions/create', label: 'Create Transaction' },
    ],
  },
];

const KeycloakContext = createContext();

export const useKeycloak = () => useContext(KeycloakContext);

// TopBar Component for search and settings
const TopBar = () => {
  const { keycloak } = useKeycloak();
  const handleLogout = () => {
    keycloak.logout();
  };

  return (
    <div
      style={{
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
      }}
    >
      <button onClick={handleLogout}>Logout</button>
    </div>
  );
};

// Main App Content with Sidebar and Routing
const AppContent = () => {
  const [current, setCurrent] = useState('1');
  const [collapsed, setCollapsed] = useState(false);
  const [drawerVisible, setDrawerVisible] = useState(false);
  const navigate = useNavigate();

  // Menu item click handler
  const handleMenuClick = (e) => {
    setCurrent(e.key);
    navigate(`/${e.key}`);
    setDrawerVisible(false); // Close drawer on mobile after selection
  };

  const toggleDrawer = () => {
    setDrawerVisible(!drawerVisible);
  };

  return (
    <Layout style={{ minHeight: '100vh' }}>
      {/* Top Bar */}
      <Header className="ant-layout-header ant-layout-header-shadow">
        <TopBar toggleDrawer={toggleDrawer} />
      </Header>

      {/* Main Layout with Sidebar and Content */}
      <Layout>
        {/* Responsive Sidebar with Drawer for Mobile */}
        <Drawer
          title="Menu"
          placement="left"
          onClose={() => setDrawerVisible(false)}
          visible={drawerVisible}
          bodyStyle={{ padding: 0 }}
        >
          <Menu
            mode="inline"
            selectedKeys={[current]}
            onClick={handleMenuClick}
            style={{ height: '100%', borderRight: 0 }}
            items={menuItems}
          />
        </Drawer>
        

        <Sider
          breakpoint="lg"
          collapsedWidth="0"
          onCollapse={(collapsed) => setCollapsed(collapsed)}
          style={{ display: drawerVisible ? 'none' : 'block' }}
          className="site-layout-background"
        >
          <Menu
            mode="inline"
            selectedKeys={[current]}
            onClick={handleMenuClick}
            style={{ height: '100%', borderRight: 0 }}
            items={menuItems}
          />
        </Sider>

        {/* Content Area */}
        <Layout style={{ padding: '0 2px' }}>
          <Content
            style={{
              padding: 24,
              margin: 0,
              minHeight: 280,
              background: '#fff',
            }}
          >
            {/* Define Routes */}
            <Routes>
              <Route path="/contacts" element={<Contacts />} />
              <Route path="/contacts/create" element={<CreateContacts />} />
              <Route path="/invoices/create" element={<CreateInvoice />} />
              <Route path="/invoices" element={<InvoicesOverview />} />
              <Route path="/invoices/edit/:id" element={<EditInvoice />} />
              <Route path="/transactions" element={<TransactionList />} />
              <Route
                path="/transactions/create"
                element={<CreateTransaction />}
              />
              <Route
                path="/transactions/edit/:transactionId"
                element={<EditTransaction />}
              />
              <Route path="/accounting" element={<Overview />} />
            </Routes>
          </Content>
        </Layout>
      </Layout>
    </Layout>
  );
};

const App = () => {
  const [authenticated, setAuthenticated] = useState(null); // Set initial state to `null`
  const [keycloakInitialized, setKeycloakInitialized] = useState(false);
  const keycloakInstance = useRef(null);

  useEffect(() => {
    if (!keycloakInstance.current) {
      keycloakInstance.current = new Keycloak({
        url: process.env.REACT_APP_KEYCLOAK_URL,
        realm: process.env.REACT_APP_KEYCLOAK_REALM,
        clientId: process.env.REACT_APP_KEYCLOAK_CLIENT_ID,
        redirectUri: process.env.REACT_APP_REDIRECT_URI
      });

      keycloakInstance.current
        .init({ onLoad: 'login-required' })
        .then((auth) => {
          setAuthenticated(auth);
          setKeycloakInitialized(true);
          if (!auth) {
            keycloakInstance.current.login();
          }

          axios.defaults.headers.common['Authorization'] = `Bearer ${keycloakInstance.current.token}`;
        })
        .catch((err) => {
          console.error('Failed to initialize Keycloak', err);
          setAuthenticated(false);
          setKeycloakInitialized(true);
        });
    }
  }, []);

  if (!keycloakInitialized) {
    return <div>Loading...</div>;
  }

  if (authenticated === false) {
    return <div>Not authenticated. Redirecting to login...</div>;
  }

  return (
    <KeycloakContext.Provider
      value={{ keycloak: keycloakInstance.current, authenticated }}
    >
      <Router>
        <AppContent />
      </Router>
    </KeycloakContext.Provider>
  );
};

export default App;
