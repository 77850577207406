import React, { useState } from 'react';
import { Form, Input, Button, notification } from 'antd';
import axios from 'axios';

export default function CreateContacts() {
  const [contacts, setContacts] = useState([]);
  const [form] = Form.useForm();

  const handleSubmit = async (values) => {
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_BASE_URL}/contacts`,
        values,
      );
      setContacts([...contacts, response.data]);
      form.resetFields();
      notification.success({
        message: 'Success',
        description: 'Contact created successfully',
      });
    } catch (error) {
      console.error('Error creating contact', error);
      notification.error({
        message: 'Error',
        description: 'Failed to create contact',
      });
    }
  };

  return (
    <div style={{ alignContent: 'left', padding: '20px' }}>
      {' '}
      {/* Align to the left */}
      <h1>Create Contact</h1>
      <Form
        form={form}
        layout="vertical"
        onFinish={handleSubmit}
        style={{ maxWidth: '600px', marginBottom: '20px' }}
      >
        <Form.Item
          name="name"
          label="Name"
          rules={[{ required: true, message: 'Please enter the contact name' }]}
        >
          <Input placeholder="Name" />
        </Form.Item>
        <Form.Item
          name="email"
          label="Email"
          rules={[
            { required: true, message: 'Please enter the contact email' },
          ]}
        >
          <Input type="email" placeholder="Email" />
        </Form.Item>
        <Form.Item
          name="phone"
          label="Phone"
          rules={[
            { required: true, message: 'Please enter the contact phone' },
          ]}
        >
          <Input placeholder="Phone" />
        </Form.Item>
        <Form.Item name="address" label="Address">
          <Input placeholder="Address" />
        </Form.Item>
        <Form.Item name="city" label="City">
          <Input placeholder="City" />
        </Form.Item>
        <Form.Item name="postalCode" label="Postal Code">
          <Input placeholder="Postal Code" />
        </Form.Item>
        <Form.Item name="country" label="Country">
          <Input placeholder="Country" />
        </Form.Item>
        <Form.Item>
          <Button type="primary" htmlType="submit">
            Add Contact
          </Button>
        </Form.Item>
      </Form>
    </div>
  );
}
