import React, { useState, useEffect } from 'react';
import {
  Form,
  Input,
  Select,
  DatePicker,
  InputNumber,
  Button,
  notification,
} from 'antd';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import dayjs from 'dayjs';

const { Option } = Select;

function CreateTransaction() {
  const [accounts, setAccounts] = useState([]);
  const navigate = useNavigate();

  useEffect(() => {
    async function fetchAccounts() {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_API_BASE_URL}/accounts`,
        );
        setAccounts(response.data);
      } catch (error) {
        console.error('Error fetching accounts', error);
        notification.error({
          message: 'Error',
          description: 'Failed to fetch accounts',
        });
      }
    }
    fetchAccounts();
  }, []);

  const handleSubmit = async (values) => {
    try {
      const formattedValues = {
        ...values,
        date: values.date.format('YYYY-MM-DD'), // Convert to ISO format for backend
      };

      await axios.post(
        `${process.env.REACT_APP_API_BASE_URL}/transactions`,
        formattedValues,
      );
      notification.success({
        message: 'Success',
        description: 'Transaction created successfully',
      });
      navigate('/transactions');
    } catch (error) {
      console.error('Error creating transaction', error);
      notification.error({
        message: 'Error',
        description: 'Failed to create transaction',
      });
    }
  };

  return (
    <Form
      layout="vertical"
      onFinish={handleSubmit}
      initialValues={{
        date: dayjs(),
      }}
      style={{ maxWidth: '600px', marginBottom: '20px' }}
    >
      <h1>Create Transaction</h1>
      <Form.Item
        name="debitAccount"
        label="Debit Account"
        rules={[{ required: true, message: 'Please select a debit account' }]}
      >
        <Select
          placeholder="Select Debit Account"
          showSearch
          style={{ width: '100%' }}
          optionFilterProp="label"
          filterOption={(input, option) =>
            option.label.toLowerCase().includes(input.toLowerCase())
          }
        >
          {accounts.map((account) => (
            <Option
              key={account._id}
              value={account._id}
              label={`${account.accountNumber} - ${account.accountName}`}
            >
              {`${account.accountNumber} - ${account.accountName}`}
            </Option>
          ))}
        </Select>
      </Form.Item>

      <Form.Item
        name="creditAccount"
        label="Credit Account"
        rules={[{ required: true, message: 'Please select a credit account' }]}
      >
        <Select
          placeholder="Select Credit Account"
          showSearch
          style={{ width: '100%' }}
          optionFilterProp="label"
          filterOption={(input, option) =>
            option.label.toLowerCase().includes(input.toLowerCase())
          }
        >
          {accounts.map((account) => (
            <Option
              key={account._id}
              value={account._id}
              label={`${account.accountNumber} - ${account.accountName}`}
            >
              {`${account.accountNumber} - ${account.accountName}`}
            </Option>
          ))}
        </Select>
      </Form.Item>

      <Form.Item
        name="date"
        label="Date"
        rules={[{ required: true, message: 'Please select a date' }]}
      >
        <DatePicker format="DD-MM-YYYY" style={{ width: '100%' }} />
      </Form.Item>

      <Form.Item
        name="description"
        label="Description"
        rules={[{ required: true, message: 'Please enter a description' }]}
      >
        <Input placeholder="Enter description" />
      </Form.Item>

      <Form.Item
        name="amount"
        label="Amount"
        rules={[{ required: true, message: 'Please enter an amount' }]}
      >
        <InputNumber
          placeholder="Enter amount"
          min={0}
          step={0.01}
          style={{ width: '100%' }}
        />
      </Form.Item>

      <Form.Item>
        <Button type="primary" htmlType="submit">
          Create Transaction
        </Button>
      </Form.Item>
    </Form>
  );
}

export default CreateTransaction;
