import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import axios from 'axios';

function EditTransaction() {
  const { transactionId } = useParams();
  const [transaction, setTransaction] = useState(null);
  const navigate = useNavigate();

  useEffect(() => {
    async function fetchTransaction() {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_API_BASE_URL}/transactions/${transactionId}`,
        );
        setTransaction(response.data);
      } catch (error) {
        console.error('Error fetching transaction', error);
      }
    }
    fetchTransaction();
  }, [transactionId]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      await axios.put(
        `${process.env.REACT_APP_API_BASE_URL}/transactions/${transactionId}`,
        transaction,
      );
      navigate('/transactions');
    } catch (error) {
      console.error('Error updating transaction', error);
    }
  };

  if (!transaction) {
    return <div>Loading...</div>;
  }

  return (
    <form onSubmit={handleSubmit}>
      <div>
        <label>Description:</label>
        <input
          type="text"
          value={transaction.description}
          onChange={(e) =>
            setTransaction({ ...transaction, description: e.target.value })
          }
        />
      </div>
      <div>
        <label>Debit:</label>
        <input
          type="number"
          value={transaction.debit}
          onChange={(e) =>
            setTransaction({
              ...transaction,
              debit: parseFloat(e.target.value),
            })
          }
        />
      </div>
      <div>
        <label>Credit:</label>
        <input
          type="number"
          value={transaction.credit}
          onChange={(e) =>
            setTransaction({
              ...transaction,
              credit: parseFloat(e.target.value),
            })
          }
        />
      </div>
      <button type="submit">Update</button>
    </form>
  );
}

export default EditTransaction;
