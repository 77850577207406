import React, { useState, useEffect } from 'react';
import { Typography, List, Spin, Row, Col, Card } from 'antd';
import axios from 'axios';

const { Title } = Typography;

function Overview() {
  const [overview, setOverview] = useState(null);

  useEffect(() => {
    async function fetchOverview() {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_API_BASE_URL}/overview`,
        );
        setOverview(response.data);
      } catch (error) {
        console.error('Error fetching overview', error);
      }
    }
    fetchOverview();
  }, []);

  if (!overview) {
    return (
      <div style={{ textAlign: 'center', padding: '50px' }}>
        <Spin size="large" />
      </div>
    );
  }

  return (
    <div>
      <Row gutter={[16, 16]}>
        {/* Balance Sheet Section */}
        <Col span={12}>
          <Card title="Bilanz (Balance Sheet)" bordered={false}>
            {/* Assets */}
            <Title level={3}>Assets</Title>
            <List
              dataSource={overview.balanceSheet.assets}
              renderItem={(acc) => (
                <List.Item>
                  <strong>{acc.accountName}:</strong> CHF{' '}
                  {acc.balance !== undefined ? acc.balance.toFixed(2) : '0.00'}
                </List.Item>
              )}
            />
            {/* Liabilities */}
            <Title level={3}>Liabilities</Title>
            <List
              dataSource={overview.balanceSheet.liabilities}
              renderItem={(acc) => (
                <List.Item>
                  <strong>{acc.accountName}:</strong> CHF{' '}
                  {acc.balance !== undefined ? acc.balance.toFixed(2) : '0.00'}
                </List.Item>
              )}
            />
            {/* Equity */}
            <Title level={3}>Equity</Title>
            <List
              dataSource={overview.balanceSheet.equity}
              renderItem={(acc) => (
                <List.Item>
                  <strong>{acc.accountName}:</strong> CHF{' '}
                  {acc.balance !== undefined ? acc.balance.toFixed(2) : '0.00'}
                </List.Item>
              )}
            />
            {/* Total Liabilities and Equity */}
            <Title level={4}>
              Total Liabilities and Equity: CHF{' '}
              {overview.balanceSheet.totalLiabilitiesAndEquity.toFixed(2)}
            </Title>
          </Card>
        </Col>

        {/* Income Statement Section */}
        <Col span={12}>
          <Card title="Erfolgsrechnung (Income Statement)" bordered={false}>
            {/* Revenue */}
            <Title level={3}>Revenue</Title>
            <List
              dataSource={overview.incomeStatement.revenue}
              renderItem={(acc) => (
                <List.Item>
                  <strong>{acc.accountName}:</strong> CHF{' '}
                  {acc.balance !== undefined ? acc.balance.toFixed(2) : '0.00'}
                </List.Item>
              )}
            />
            {/* Expenses */}
            <Title level={3}>Expenses</Title>
            <List
              dataSource={overview.incomeStatement.expenses}
              renderItem={(acc) => (
                <List.Item>
                  <strong>{acc.accountName}:</strong> CHF{' '}
                  {acc.balance !== undefined ? acc.balance.toFixed(2) : '0.00'}
                </List.Item>
              )}
            />
            {/* Net Income */}
            <Title level={4}>
              Net Income: CHF {overview.incomeStatement.netIncome.toFixed(2)}
            </Title>
          </Card>
        </Col>
      </Row>
    </div>
  );
}

export default Overview;
