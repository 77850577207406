import React, { useState, useEffect } from 'react';
import {
  Table,
  Button,
  message,
  Modal,
  Form,
  Input,
  Card,
  Row,
  Col,
} from 'antd';
import axios from 'axios';

export default function Contacts() {
  const [contacts, setContacts] = useState([]);
  const [filteredContacts, setFilteredContacts] = useState([]);
  const [loading, setLoading] = useState(false);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [editingContact, setEditingContact] = useState(null);
  const [form] = Form.useForm();
  const [filterForm] = Form.useForm();

  useEffect(() => {
    fetchContacts();
  }, []);

  const fetchContacts = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_BASE_URL}/contacts`,
      );
      setContacts(response.data);
      setFilteredContacts(response.data);
    } catch (error) {
      console.error('Error fetching contacts', error);
    }
  };

  const syncContacts = async () => {
    setLoading(true);
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_BASE_URL}/sync-contacts`,
      );
      message.success(response.data.message);
      fetchContacts(); // Refresh contacts after syncing
    } catch (error) {
      console.error('Error syncing contacts', error);
      message.error('Failed to sync contacts with Nextcloud.');
    } finally {
      setLoading(false);
    }
  };

  const handleEdit = (record) => {
    setEditingContact(record); // Set the contact to be edited
    setIsModalVisible(true); // Show modal
    form.setFieldsValue(record); // Set form values to the selected contact
  };

  const handleCancel = () => {
    setIsModalVisible(false);
    setEditingContact(null); // Reset editing contact
    form.resetFields(); // Reset form fields
  };

  const handleSave = async (values) => {
    if (editingContact) {
      try {
        await axios.put(
          `${process.env.REACT_APP_API_BASE_URL}/contacts/${editingContact._id}`,
          values,
        );
        message.success('Contact updated successfully');
        fetchContacts(); // Refresh the contact list after editing
        handleCancel(); // Close modal and reset editing state
      } catch (error) {
        console.error('Error updating contact', error);
        message.error('Failed to update contact.');
      }
    }
  };

  const handleFilterChange = (changedValues) => {
    const filters = filterForm.getFieldsValue();
    const filteredData = contacts.filter((contact) =>
      Object.keys(filters).every(
        (key) =>
          !filters[key] ||
          contact[key]
            ?.toString()
            .toLowerCase()
            .includes(filters[key].toLowerCase()),
      ),
    );
    setFilteredContacts(filteredData);
  };

  const columns = [
    {
      title: 'Name',
      dataIndex: 'name',
      key: 'name',
    },
    {
      title: 'Email',
      dataIndex: 'email',
      key: 'email',
    },
    {
      title: 'Phone',
      dataIndex: 'phone',
      key: 'phone',
    },
    {
      title: 'Address',
      dataIndex: 'address',
      key: 'address',
    },
    {
      title: 'City',
      dataIndex: 'city',
      key: 'city',
    },
    {
      title: 'Postal Code',
      dataIndex: 'postalCode',
      key: 'postalCode',
    },
    {
      title: 'Country',
      dataIndex: 'country',
      key: 'country',
    },
    {
      title: 'Action',
      key: 'action',
      render: (text, record) => (
        <Button type="link" onClick={() => handleEdit(record)}>
          Edit
        </Button>
      ),
    },
  ];

  return (
    <div>
      <h2>Contact Overview</h2>
      {/* Filter Card */}
      <Card style={{ marginBottom: '20px' }}>
        <Form
          form={filterForm}
          layout="vertical"
          onValuesChange={handleFilterChange}
        >
          <Row gutter={16}>
            <Col span={6}>
              <Form.Item label="Name" name="name">
                <Input placeholder="Filter by Name" />
              </Form.Item>
            </Col>
            <Col span={6}>
              <Form.Item label="Email" name="email">
                <Input placeholder="Filter by Email" />
              </Form.Item>
            </Col>
            <Col span={6}>
              <Form.Item label="City" name="city">
                <Input placeholder="Filter by City" />
              </Form.Item>
            </Col>
            <Col span={6}>
              <Form.Item label="Country" name="country">
                <Input placeholder="Filter by Country" />
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </Card>
      {/* Button to trigger sync */}
      <Button type="primary" onClick={syncContacts} loading={loading}>
        Sync Contacts with Nextcloud
      </Button>
      {/* Table to display contacts */}
      <Table
        columns={columns}
        dataSource={filteredContacts}
        rowKey="_id"
        pagination={{ pageSize: 10 }}
        style={{ marginTop: '20px' }}
      />
      {/* Modal for editing contact */}
      <Modal
        title="Edit Contact"
        visible={isModalVisible}
        onCancel={handleCancel}
        onOk={() => form.submit()} // Trigger form submit on OK button
      >
        <Form form={form} layout="vertical" onFinish={handleSave}>
          <Form.Item
            label="Name"
            name="name"
            rules={[{ required: true, message: 'Please enter the name' }]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            label="Email"
            name="email"
            rules={[{ required: true, message: 'Please enter the email' }]}
          >
            <Input />
          </Form.Item>
          <Form.Item label="Phone" name="phone">
            <Input />
          </Form.Item>
          <Form.Item label="Address" name="address">
            <Input />
          </Form.Item>
          <Form.Item label="City" name="city">
            <Input />
          </Form.Item>
          <Form.Item label="Postal Code" name="postalCode">
            <Input />
          </Form.Item>
          <Form.Item label="Country" name="country">
            <Input />
          </Form.Item>
        </Form>
      </Modal>
    </div>
  );
}
