import React, { useState, useEffect } from 'react';
import { Form, Input, Select, DatePicker, Button, notification } from 'antd';
import axios from 'axios';
import dayjs from 'dayjs';

const { Option } = Select;

export default function CreateInvoice({ invoiceId = null }) {
  const [contacts, setContacts] = useState([]);
  const [selectedContact, setSelectedContact] = useState(null);
  const [items, setItems] = useState([
    { description: '', quantity: 1, price: 0 },
  ]);
  const [dueDate, setDueDate] = useState(dayjs());
  const [invoiceDate, setInvoiceDate] = useState(dayjs());
  const [isEditMode, setIsEditMode] = useState(false);
  const [form] = Form.useForm();

  useEffect(() => {
    const fetchContacts = async () => {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_API_BASE_URL}/contacts`,
        );
        setContacts(response.data);
      } catch (error) {
        console.error('Error fetching contacts', error);
      }
    };

    fetchContacts();
  }, []);

  useEffect(() => {
    if (invoiceId) {
      setIsEditMode(true);
      const fetchInvoice = async () => {
        try {
          const response = await axios.get(
            `${process.env.REACT_APP_API_BASE_URL}/invoices/${invoiceId}`,
          );
          const invoice = response.data;
          setSelectedContact(invoice.contact._id);
          setItems(invoice.items);
          setDueDate(dayjs(invoice.dueDate));
          setInvoiceDate(dayjs(invoice.invoiceDate));
          fetchContactDetails(invoice.contact._id);
        } catch (error) {
          console.error('Error fetching invoice', error);
        }
      };

      fetchInvoice();
    }
  }, [invoiceId]);

  const fetchContactDetails = async (contactId) => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_BASE_URL}/contacts/${contactId}`,
      );
      const contactDetails = response.data;

      form.setFieldsValue({
        email: contactDetails.email,
        address: contactDetails.address,
        phone: contactDetails.phone,
        city: contactDetails.city,
        postal_code: contactDetails.postalCode,
        country: contactDetails.country,
      });
    } catch (error) {
      console.error('Error fetching contact details', error);
    }
  };

  const handleContactChange = (value) => {
    setSelectedContact(value);
    fetchContactDetails(value);
  };

  const handleAddItem = () => {
    setItems([...items, { description: '', quantity: 1, price: 0 }]);
  };

  const handleItemChange = (index, key, value) => {
    const newItems = [...items];
    newItems[index][key] = value;
    setItems(newItems);
  };

  const handleSubmit = async () => {
    const contactDetails = form.getFieldsValue(['email', 'address', 'phone']);

    // Validate contact details
    if (
      !contactDetails.email ||
      !contactDetails.address ||
      !contactDetails.phone
    ) {
      notification.error({
        message: 'Error',
        description:
          'Please fill in all contact details before creating an invoice.',
      });
      return;
    }

    try {
      // Update contact details
      let contactResponse;
      if (isEditMode) {
        contactResponse = await axios.put(
          `${process.env.REACT_APP_API_BASE_URL}/contacts/${selectedContact}`,
          contactDetails,
        );
      } else {
        contactResponse = await axios.post(
          `${process.env.REACT_APP_API_BASE_URL}/contacts/${selectedContact}`,
          contactDetails,
        );
        setSelectedContact(contactResponse.data._id); // Update selected contact with the new contact's ID
      }

      if (contactResponse.status === 200) {
        notification.success({
          message: 'Success',
          description: 'Contact details updated successfully.',
        });

        // Prepare invoice data
        const totalAmount = items.reduce(
          (sum, item) => sum + item.quantity * item.price,
          0,
        );
        const invoice = {
          contact: selectedContact,
          items,
          totalAmount,
          dueDate: dueDate.format('YYYY-MM-DD'),
          invoiceDate: invoiceDate.format('YYYY-MM-DD'),
        };

        // Send invoice data to the backend
        let response;
        if (isEditMode) {
          response = await axios.put(
            `${process.env.REACT_APP_API_BASE_URL}/invoices/${invoiceId}`,
            invoice,
            { responseType: 'blob' },
          );
        } else {
          response = await axios.post(
            `${process.env.REACT_APP_API_BASE_URL}/invoices/pdf`,
            invoice,
            { responseType: 'blob' },
          );
        }

        // Handle invoice download
        if (response.data) {
          const url = window.URL.createObjectURL(new Blob([response.data]));
          const link = document.createElement('a');
          link.href = url;
          link.setAttribute('download', `invoice_${invoiceId || 'new'}.pdf`);
          document.body.appendChild(link);
          link.click();
          link.remove();
        }

        notification.success({
          message: 'Success',
          description: isEditMode
            ? 'Invoice updated successfully'
            : 'Invoice created successfully',
        });
      } else {
        throw new Error('Failed to update contact details.');
      }
    } catch (error) {
      console.error('Error creating/updating invoice', error);
      notification.error({
        message: 'Error',
        description: 'Failed to create/update invoice or contact.',
      });
    }
  };

  return (
    <div style={{ alignContent: 'left', padding: '20px' }}>
      <Form
        layout="vertical"
        form={form} // Connect form to Form component
        onFinish={handleSubmit}
        style={{ maxWidth: '600px', marginBottom: '20px' }}
      >
        <h1>{isEditMode ? 'Edit Invoice' : 'Create Invoice'}</h1>

        <Form.Item
          label="Select Contact"
          name="contact"
          rules={[{ required: true, message: 'Please select a contact!' }]}
        >
          <Select
            value={selectedContact}
            onChange={handleContactChange}
            optionFilterProp="label"
            placeholder="Select a contact"
            showSearch
            filterOption={(input, option) =>
              option.label.toLowerCase().includes(input.toLowerCase())
            }
          >
            {contacts.map((contact) => (
              <Option
                key={contact._id}
                value={contact._id}
                label={contact.name}
              >
                {contact.name}
              </Option>
            ))}
          </Select>
        </Form.Item>

        {selectedContact && (
          <>
            <Form.Item
              label="Contact Email"
              name="email"
              rules={[{ required: true, message: 'Please enter an email!' }]}
            >
              <Input />
            </Form.Item>
            <Form.Item
              label="Contact Phone"
              name="phone"
              rules={[
                { required: true, message: 'Please enter a phone number!' },
              ]}
            >
              <Input />
            </Form.Item>

            <Form.Item
              label="Contact Address"
              name="address"
              rules={[{ required: true, message: 'Please enter an address!' }]}
            >
              <Input />
            </Form.Item>
            <Form.Item
              label="Contact City"
              name="city"
              rules={[
                { required: true, message: 'Please enter a contact city!' },
              ]}
            >
              <Input />
            </Form.Item>
            <Form.Item
              label="Contact Postal Code"
              name="postal_code"
              rules={[
                { required: true, message: 'Please enter a postal code!' },
              ]}
            >
              <Input />
            </Form.Item>
            <Form.Item
              label="Contact country"
              name="country"
              rules={[{ required: true, message: 'Please enter a country!' }]}
            >
              <Input />
            </Form.Item>
          </>
        )}

        <Form.Item
          label="Due Date"
          name="dueDate"
          rules={[{ required: true, message: 'Please select a due date!' }]}
        >
          <DatePicker
            value={dueDate}
            onChange={setDueDate}
            format="YYYY-MM-DD"
            style={{ width: '100%' }}
          />
        </Form.Item>

        <Form.Item
          label="Invoice Date"
          name="invoiceDate"
          rules={[
            { required: true, message: 'Please select an invoice date!' },
          ]}
        >
          <DatePicker
            value={invoiceDate}
            onChange={setInvoiceDate}
            format="YYYY-MM-DD"
            style={{ width: '100%' }}
          />
        </Form.Item>

        {items.map((item, index) => (
          <div key={index} style={{ marginBottom: '20px' }}>
            <Form.Item
              label="Description"
              name={`description_${index}`}
              rules={[
                { required: true, message: 'Please enter a description!' },
              ]}
            >
              <Input
                placeholder="Description"
                value={item.description}
                onChange={(e) =>
                  handleItemChange(index, 'description', e.target.value)
                }
              />
            </Form.Item>
            <Form.Item
              label="Quantity"
              name={`quantity_${index}`}
              rules={[{ required: true, message: 'Please enter a quantity!' }]}
            >
              <Input
                type="number"
                min="0"
                step="0.01"
                value={item.quantity}
                onChange={(e) =>
                  handleItemChange(index, 'quantity', e.target.value)
                }
              />
            </Form.Item>
            <Form.Item
              label="Price"
              name={`price_${index}`}
              rules={[{ required: true, message: 'Please enter a price!' }]}
            >
              <Input
                type="number"
                min="0"
                step="0.01"
                value={item.price}
                onChange={(e) =>
                  handleItemChange(index, 'price', e.target.value)
                }
              />
            </Form.Item>
          </div>
        ))}

        <Button
          type="dashed"
          onClick={handleAddItem}
          style={{ marginBottom: '20px' }}
        >
          Add Item
        </Button>

        <Button type="primary" htmlType="submit">
          {isEditMode ? 'Update Invoice' : 'Create Invoice'}
        </Button>
      </Form>
    </div>
  );
}
